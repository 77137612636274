import React, { Component }     from 'react';
import '../pages.css';

import About                    from './About';
import Info                     from './Info';
import Contact                  from './Subscribe';

export default class HomePage extends Component {
    render(){
        return (
            <div className="home-page page-wrapper">
                <About />
                <Info />
                <Contact />
                {/* <iframe src="https://www.zeffy.com/en-US/donation-form/a6566626-bb00-4749-a342-be73be02b50e"></iframe> */}
            </div>
        );
    };
};