import React            from 'react';
import TextiO           from '../../components/TextiO';
import EmailForm        from '../../components/EmailForm';

const Subscribe = () => {

    return(
        <section className="home-subscribe item page-width">
            <div className='grid desktop-two-col mobile-one-col'>
                <section className="img left bottom txt-over-img-box" style={{paddingTop:'100%'}}>
                    <div className="img-background"><img src="brown-wooden-floor_wide.jpg" alt="Window-light on wooden floor" /></div>
                    <div className="txt-foreground">
                        <div>
                            <TextiO>
                                <h2>There is Hope</h2>
                            </TextiO>
                        </div>
                    </div>
                </section>
                <section className="txt right top">
                    <div style={{padding: '2rem 0', width: '100%'}}>
                        <h2 style={{paddingBottom: '.5rem'}}>Subscribe</h2>
                        <p>Stay up to date on what is happening.</p>
                        <EmailForm form="newsletter" /> 
                    </div>
                </section>
            </div>
        </section>
    );
};

export default Subscribe;