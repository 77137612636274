import React    from 'react';
import Nav      from './Nav';

const Header = () => {
    return(
        <header>
            <section className="header-logo">
                <img className="name-logo desktop-only" width="50%" height="auto" src="Kimberly_Charter_Ministries-logo.png" alt="Kimberly Charter Ministries"/>
                <img className="name-logo mobile-only" width="50%" height="auto" src="KCM-logo.png" alt="Kimberly Charter Ministries"/>
            </section>
            <section className="header-nav">
                <Nav />
            </section>
            <section className="header-donate">
                <a className="donate-btn" href="https://www.zeffy.com/en-US/donation-form/a6566626-bb00-4749-a342-be73be02b50e" target="_blank"  rel="noopener">DONATE</a>
            </section>
        </header>
    );
};

export default Header;